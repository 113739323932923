import React from 'react';
import { CssBaseline, Container } from '@mui/material';
import Routes from './routes';
import './App.css'
import StickyIcons from './components/screens/StickyIcons'; // Import the StickyIcons component

const App = () => {
  return (
    <>
      <CssBaseline />
      <Container maxWidth={false}>
        <Routes />
        <StickyIcons />
      </Container>
      {/* Add StickyIcons here */}

    </>
  );
};

export default App;
