import React from 'react';
import {
  Typography, Grid, IconButton, Box, Divider, List, ListItem, ListItemText,
} from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';
import logoImage from '../../assets/images/logo.jpeg';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import '../../assets/css/footer.css'; // Import the CSS file

const Footer = () => {
  const navigate = useNavigate();
  const handleNavigation = (path, isExternal) => {
    if (isExternal) {
      window.open(path, '_blank', 'noopener,noreferrer');
    } else {
      navigate(path);
    }
  };

  const items = [
    { text: 'Sell Phone', path: '/sell/phone' },
    { text: 'Sell Laptop', path: '/sell/laptop' },
    { text: 'Sell Smart Watch', path: '/sell/smart-watch' },
    { text: 'Sell DSLR Camera', path: '/sell/dslr-camera' },
    { text: 'Sell Earbuds', path: '/sell/earbuds' },
    { text: 'Repair Phone', path: '/repairphone' },
    { text: 'Buy Phone', path: '/buyphone' },
    { text: 'Partner With Us', path: '/partner' },
  ];

  const items1 = [
    { text: 'About us', path: '/about' },
    { text: 'Become a cash on spot partner', path: 'https://docs.google.com/forms/d/e/1FAIpQLScyGYbfbqQxEaHXfMYivRYhw-pU4SjcV5Ghnp9VJqUaBfDiEQ/viewform?vc=0&c=0&w=1&flr=0', isExternal: true },
  ];

  const items2 = [
    { text: 'Mobile Phone', path: '/sell/phone' },
    { text: 'Laptop', path: '/sell/laptop' },
    { text: 'Tablet', path: '/sell/tablet' },
    { text: 'iMac', path: '/sell/iMac' },
  ];


  const items4 = [
    { text: 'FAQs', path: '/faqs' },
    { text: 'Blogs', path: '/blogs' },
    { text: 'Terms & Conditions', path: '/terms' },
    { text: 'Privacy Policy', path: '/privacy' },
    { text: 'Cookies Policy', path: '/cookies' },
    { text: 'Cancellation Policy', path: '/cancellation' },
    { text: 'Grievance Policy', path: '/grievance' },
    { text: 'Shipping Policy', path: '/shipping' },
  ];

  return (
    <Box sx={{ bgcolor: '#f8f8f8', p: 4, mt: 8 }} className="footer-container">
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={2} className="footer-item">
          <Box textAlign="center" className="footer-logo">
            <img src={logoImage} alt="logo" width="150" />
          </Box>
          <Box textAlign="center" sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton href="https://www.facebook.com" target="_blank" className="social-icon">
              <Facebook />
            </IconButton>
            <IconButton href="https://www.twitter.com" target="_blank" className="social-icon">
              <Twitter />
            </IconButton>
            <IconButton href="https://www.instagram.com" target="_blank" className="social-icon">
              <Instagram />
            </IconButton>
            <IconButton href="https://www.linkedin.com" target="_blank" className="social-icon">
              <LinkedIn />
            </IconButton>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={2} className="footer-item">
          <Typography variant="h6" className="footer-heading">Services</Typography>
          <List className="footer-menu">
            {items.map(({ text, path }) => (
              <ListItem key={text} button onClick={() => handleNavigation(path)} className="footer-menu-item">
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12} sm={6} md={2} className="footer-item">
          <Typography variant="h6" className="footer-heading">Company</Typography>
          <List className="footer-menu">
            {items1.map(({ text, path, isExternal }) => (
              <ListItem key={text} button onClick={() => handleNavigation(path, isExternal)} className="footer-menu-item">
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12} sm={6} md={2} className="footer-item">
          <Typography variant="h6" className="footer-heading">Sell Device</Typography>
          <List className="footer-menu">
            {items2.map(({ text, path }) => (
              <ListItem key={text} button onClick={() => handleNavigation(path)} className="footer-menu-item">
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12} sm={6} md={2} className="footer-item">
          <Typography variant="h6" className="footer-heading">Help & Support</Typography>
          <List className="footer-menu">
            {['Contact Us'].map((text) => (
              <ListItem key={text} className="footer-menu-item">
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12} sm={6} md={2} className="footer-item">
          <Typography variant="h6" className="footer-heading">More Info</Typography>
          <List className="footer-menu">
      {items4.map((item) => (
        <ListItem
          key={item.text}
          className="footer-menu-item"
          component={Link}
          to={item.path}
        >
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Typography variant="body2" color="textSecondary" align="center" className="footer-copy">
        © {new Date().getFullYear()} cashonspot.in - All rights reserved/SM Enterprises.
      </Typography>
    </Box>
  );
}

export default Footer;
