import React from 'react';
import { Box, Typography, Card, CardContent, Button, IconButton, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import cosimg from "../../assets/images/cos_outside.jpeg";
import handshakes from "../../assets/images/handshakes.jpeg";
import persons from "../../assets/images/persons.jpeg";
import NoImage from "../../assets/images/Thumbnail.jpg";

const Aboutus = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <Box sx={{ padding: '20px', backgroundColor: 'linear-gradient(to right, #0f4c81, #7c31db) !important' }}>
      <IconButton onClick={handleBackClick}>
        <Button startIcon={<ArrowBackIcon />}>Go back</Button>
      </IconButton>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              backgroundColor: '#E0E7FF',
              borderRadius: '8px',
              padding: '20px'
            }}
          >
            <img
              src={cosimg ? cosimg : NoImage} // Replace with your actual image path
              alt="Company Logo"
              style={{ width: '30%', height: 'auto', borderRadius: '8px',objectFit:"contain" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', backgroundColor: '#E0E7FF' }}>
            <CardContent>
              <Typography variant="h4" component="h2" sx={{ marginBottom: '20px', textAlign: 'center' }}>
                Why Cashonspot?
              </Typography>
              <Typography variant="body1" component="p">
                At Cashonspot, we’re passionate about connecting people with the latest mobile technology while
                providing exceptional service and value. With years of experience in the industry, we’ve built a
                reputation for reliability, trustworthiness, and customer satisfaction.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', backgroundColor: '#F3F4F6' }}>
            <CardContent>
              <Typography variant="h5" component="h3" sx={{ marginBottom: '10px' }}>
                Our Mission:
              </Typography>
              <Typography variant="body1" component="p">
                Our mission is simple: to make buying mobile devices easy, convenient, and secure for everyone. While
                you are selling your old phone, we’re here to guide you every step of the way and provide you the best
                price ever.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', backgroundColor: '#E0E7FF' }}>
            <CardContent>
              <Typography variant="h5" component="h3" sx={{ marginBottom: '10px' }}>
                Our Services:
              </Typography>
              <Typography variant="body1" component="ul">
                <li>Convenient selling options: Selling your old device is easy with our hassle-free selling process.</li>
                <li>Fastest Pickup: We provide you the fastest pickup and transfer your money on the spot.</li>
                <li>Secure Transactions: Your security and privacy are our top priorities.</li>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
      <Card sx={{ marginTop: '20px', padding: '20px', backgroundColor: '#F3F4F6' }}>
        <CardContent>
           <img
              src={persons ? persons : NoImage} // Replace with your actual image path
              alt="Company Logo"
              style={{ width: '30%', height: 'auto', borderRadius: '8px',objectFit:"contain" }}
            />
          <Typography variant="h5" component="h3" sx={{ marginBottom: '10px' }}>
            Our Team:
          </Typography>
          <Typography variant="body1" component="p">
            Behind Cashonspot is a dedicated team of professionals who are committed to providing you with the best
            possible experience. From our customer support representatives to our technical experts, each member of our
            team plays a crucial role in ensuring your satisfaction.
          </Typography>
        </CardContent>
      </Card>
      </Grid>
      <Grid item xs={12} md={6}>
      <Card sx={{ marginTop: '20px', padding: '20px', backgroundColor: '#E0E7FF' }}>
        <CardContent>
                <img
              src={handshakes ? handshakes : NoImage} // Replace with your actual image path
              alt="Company Logo"
              style={{ width: '30%', height: 'auto', borderRadius: '8px',objectFit:"contain" }}
            />
          <Typography variant="h5" component="h3" sx={{ marginBottom: '10px' }}>
            Get in Touch:
          </Typography>
          <Typography variant="body1" component="p">
            Have questions or need assistance? Our friendly customer support team is here to help. Contact us via phone,
            email, or live chat, and we’ll be happy to assist you.
          </Typography>
        </CardContent>
      </Card>
      </Grid>
      </Grid>
     
    </Box>
  );
};

export default Aboutus;
