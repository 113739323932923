import React, { useState, useEffect, useRef } from 'react';
import { TextField, IconButton, Popover, List, ListItem, ListItemText, Paper, CircularProgress, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import {config} from "../../config"


import { debounce } from 'lodash';
import '../../assets/css/common.css'; // Custom CSS for styling

const SearchComponent = ({ onSelect }) => {
  const [query, setQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [showPopover, setShowPopover] = useState(false);
  const inputRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:600px)');


  const debouncedSearch = debounce(async (query, setSearchResults, setShowPopover) => {
    const apiurl = `${config.Api}search`;
    setLoading(true); // Start loading
    try {
      const response = await fetch(apiurl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
      });
      const data = await response.json();
      setSearchResults(data);
      setShowPopover(true);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setShowPopover(false);
    }finally {
      setLoading(false); // Stop loading after data is fetched
    }
  }, 300);

  useEffect(() => {
    if (query) {
      setLoading(true); // Show loader when typing
      debouncedSearch(query, setSearchResults, setShowPopover);
    } else {
      setSearchResults([]);
      setShowPopover(false);
      setLoading(false); // Stop loading if query is cleared
    }
  }, [query]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setAnchorEl(event.currentTarget);
  };

  const handleClearSearch = () => {
    setQuery('');
    inputRef.current.focus(); // Keep focus on input field
  };

  const handleClosePopover = () => {
    setShowPopover(false);
  };

  const handleSearchSelect = (selectedItem) => {
    onSelect(selectedItem);
    handleClosePopover();
  };

  return (
    <div className="search-container">
      <TextField
        value={query}
        onChange={handleInputChange}
        placeholder="Search Model,Brand,..."
        variant="outlined"
        InputProps={{
          startAdornment: loading ? ( // Show loader when loading
            <InputAdornment position="start">
              <CircularProgress size={20} />
            </InputAdornment>
          ) : null,
          endAdornment: query ? (
            <IconButton onClick={handleClearSearch}>
              <CloseIcon /> 
            </IconButton>
          ) : null,
        }}
        fullWidth
        className="search-input"
        inputRef={inputRef} // Set input reference

      />
      <Popover
        open={showPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: isMobile ? '100%' : anchorEl ? anchorEl.clientWidth : undefined,
            maxWidth: isMobile ? '95vw' : undefined,
          },
        }}
      >
       <Paper className="popover-content">
          <List>
            {searchResults.length > 0 ? (
              searchResults.map((result, index) => (
                <ListItem button key={index} onClick={() => handleSearchSelect(result)}>
                  <ListItemText primary={`${result["Brand name"]} ${result["Model"]}`} />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No records found" />
              </ListItem>
            )}
          </List>
        </Paper>
      </Popover>
    </div>
  );
};

export default SearchComponent;
