import React,{useState} from 'react';
import {
  AppBar, Toolbar, Container, Typography, Grid, Card, CardActionArea, CardContent, CardMedia, InputBase, Button, Menu, MenuItem, IconButton, Box, Accordion, AccordionSummary, AccordionDetails, Divider, Dialog, DialogTitle, TextField, List, ListItem, ListItemText,
  ListItemAvatar,
  Avatar,
  ListSubheader,
  Popover
  
} from '@mui/material';
import { Search, LocationOn, ExpandMore, Close,ArrowDropUp,ArrowDropDown } from '@mui/icons-material';


const FAQ = () => {
  return (
    <div>
      <Typography variant="h5" align="center" style={{ margin: '40px 0 20px' }} className='sell-old'>
Frequently Asked Questions
</Typography>
<Accordion>
<AccordionSummary expandIcon={<ExpandMore />}>
  <Typography className='cosacc'>What are the easiest steps to sell my old device on cashonspot.in?</Typography>
</AccordionSummary>
<AccordionDetails>
  <Typography className='cosacc'>
    1. <strong>Device Assessment:</strong> Provide details about your old device, such as its model, condition, and any accessories it comes with. This information helps determine its value and ensures an accurate quote.<br /><br/>
    2. <strong>Get a Quote:</strong> Receive an instant quote based on the information provided. This quote reflects the current market value of your device.<br /><br />
    3. <strong>Pickup and Payment:</strong> If you accept the quote, follow the instructions to Pickup your device to the buyer. Once received and inspected, you’ll receive payment via your preferred method, such as bank transfer or UPI.
  </Typography>
</AccordionDetails>
</Accordion>
<Accordion>
<AccordionSummary expandIcon={<ExpandMore />}>
  <Typography className='cosacc'>What security measures are in place to protect my personal information during transactions to sell my device?</Typography>
</AccordionSummary>
<AccordionDetails>
  <Typography className='cosacc'>
    1. <strong>Payment Security:</strong> The website utilizes trusted payment gateways and encryption methods to securely process payment information, such as credit card details or bank account information, ensuring that your financial data is protected.<br /><br />
    2. <strong>Data Privacy:</strong> The website has strict privacy policies in place to safeguard your personal information. This includes guidelines on data collection, usage, storage, and sharing practices, ensuring that your data is handled securely and in accordance with relevant regulations.<br /><br />
    3. <strong>Secure Transactions:</strong> The website employs fraud detection mechanisms and transaction monitoring tools to identify and prevent suspicious activities, reducing the risk of fraudulent transactions.
  </Typography>
</AccordionDetails>
</Accordion>
    </div>
  )
}

export default FAQ
