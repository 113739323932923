import React from 'react'
import { Box, Typography, Grid, Card, CardActionArea, CardContent, CardMedia, Button, IconButton } from '@mui/material';
import image1 from '../../assets/images/cos1.jpeg'
import image2 from '../../assets/images/cos2.jpeg'
import image3 from '../../assets/images/cos3.jpeg'

const CashonSpotWorks = () => {
  return (
    <div>
          <Box sx={{ mt: 4 }}>
          <Typography variant="h5" align="center" className='sell-old'>How Cash on Spot Works</Typography>
          <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
            <Grid item xs={12} md={4}>
            <img src={image2} alt="" style={{ objectFit: 'contain', display: 'block', margin: 'auto', maxWidth: '80px' }}/>

              <Typography variant="h6" align="center" className='sell-old'>Step 1</Typography>
              <Typography align="center" className='coswdesc'>Provide details about your device</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
            <img src={image1} alt="" style={{ objectFit: 'contain', display: 'block', margin: 'auto', maxWidth: '80px' }}/>

              <Typography variant="h6" align="center" className='sell-old'>Step 2</Typography>
              <Typography align="center" className='coswdesc'>Get an instant quote</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
            <img src={image3} alt="" style={{ objectFit: 'contain', display: 'block', margin: 'auto', maxWidth: '80px' }}/>

              <Typography variant="h6" align="center" className='sell-old'>Step 3</Typography>
              <Typography align="center" className='coswdesc'>Schedule a pickup</Typography>
            </Grid>
          </Grid>
        </Box>
    </div>
  )
}

export default CashonSpotWorks
