import React,{useState} from 'react'

import {
    Grid,IconButton, Divider, Dialog, DialogTitle, TextField, List, ListItem, ListItemText,
    ListItemAvatar,
    Avatar,
    ListSubheader,
    Box,
    useMediaQuery, useTheme
    
  } from '@mui/material';
  import {Close } from '@mui/icons-material';



const CityDialog = ({ openCityDialog, handleCloseCityDialog,setSelectedCity,onCitySelect}) => {
    const [searchInput, setSearchInput] = useState('');

  
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const cities = {
        popular: [
          { name: 'Ahmedabad', image: 'https://i.ibb.co/r4QyDtp/ahemdabad.jpg' },
          { name: 'Kolkata', image: 'https://i.ibb.co/Gc3H7BR/kolkata.jpg' },
          { name: 'Mumbai', image: 'https://i.ibb.co/bbQkLVk/mumbai.jpg' },
          { name: 'Chennai', image: 'https://i.ibb.co/HqG2Fq0/chennai.jpg' },
          { name: 'Hyderabad', image: 'https://i.ibb.co/QDnj3Bh/hydrabad.jpg' },
          { name: 'Mysore', image: 'https://i.ibb.co/QfFzKP4/mysore.jpg' },
          { name: 'Bangalore', image: 'https://i.ibb.co/tpP0sP6/Bangalore.jpg' },
          { name: 'Mandya', image: 'https://i.ibb.co/d22gf0N/mandya.jpg' },
          { name: 'Gurgaon', image: 'https://i.ibb.co/88TYT7z/Gurgaon.jpg' },
          { name: 'Pune', image: 'https://i.ibb.co/F0DpJgg/pune.jpg' },
          { name: 'Noida', image: 'https://i.ibb.co/XD9VmQp/nooida.jpg' },
          { name: 'Delhi', image: 'https://i.ibb.co/0CX5CvB/delhi.jpg' },
        ],
        
      };
    const handleSearchChange = (event) => {
      setSearchInput(event.target.value);
    };
    
    const handleCitySelect = (cityName) => {
      setSelectedCity(cityName);
      onCitySelect(cityName);  
      handleCloseCityDialog();
    };
    
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && filteredCities.length > 0) {
        handleCitySelect(filteredCities[0].name);
      }
    };
    

    
    const filteredCities = [
      ...cities.popular,
    ].filter(city => city.name.toLowerCase().includes(searchInput.toLowerCase()));
  
    return (
        <Dialog open={openCityDialog}
         onClose={handleCloseCityDialog} 
         maxWidth="md" 
         fullWidth
        //  fullScreen={isMobile}

         >

        <DialogTitle>
            Select your City
            <IconButton 
            aria-label="close" 
            onClick={handleCloseCityDialog}
             sx={{ position: 'absolute', right: 8, top: 8 }}
             >
                <Close />
            </IconButton>
        </DialogTitle>
        <TextField 
            label="Search city" 
            variant="outlined" 
            fullWidth 
            sx={{ margin: '10px' }} 
            value={searchInput}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
        />
        <List subheader={<ListSubheader>Popular Cities</ListSubheader>}>
            <Grid container spacing={2}>
              {filteredCities.map((city) => (
                <Grid item key={city.name} xs={4} sm={4} md={4} lg={4}>
                  <ListItem button onClick={() => handleCitySelect(city.name)} disableGutters>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
                      <Avatar src={city.image} alt={city.name} sx={{ width: 70, height: 56, mb: 1 }} />
                      <ListItemText primary={city.name} sx={{ textAlign: 'center' }} />
                    </Box>
                  </ListItem>
                </Grid>
        ))}
      </Grid>
    </List>
        <Divider />
    </Dialog>
    );
  };

  export default CityDialog;