// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import wrapperReducer from './WrapperSlice'; // Corrected file path

const store = configureStore({
  reducer: {
    wrapper: wrapperReducer, // Use "wrapper" to match the slice name
  },
});

export default store;
