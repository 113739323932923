import React, { useState } from 'react';
import {
    AppBar, Toolbar, Typography, Button, Popover, List, ListItem, ListItemIcon, ListItemText, Grid, Box, Drawer, IconButton, useMediaQuery,
  } from '@mui/material';
  import {
    ArrowDropDown, ArrowDropUp, Phone, Laptop, Tablet, DesktopMac, MoreHoriz, ContactPhone, Star, Business, Description, Menu as MenuIcon,
    Info as InfoIcon,
    Business as BusinessIcon,
    Sell as SellIcon,
    MoreHoriz as MoreHorizIcon,
    
  } from '@mui/icons-material';//   import {
//   ArrowDropDown, ArrowDropUp, Phone, Laptop, Tablet, DesktopMac, MoreHoriz, ContactPhone, Star, Business, Description, Menu as MenuIcon,
// } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../assets/css/Navbar.css'; // Custom CSS for styling
 


const Navbar = () => {
    const [sellAnchorEl, setSellAnchorEl] = useState(null);
    const [moreAnchorEl, setMoreAnchorEl] = useState(null);
    const [hoveredMenu, setHoveredMenu] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    const history = useNavigate();

    const handleMenuToggle = (event, menu) => {
      event.stopPropagation();  // Prevent closing the drawer

      if (menu === 'sell') {
          setSellAnchorEl(event.currentTarget);
      } else if (menu === 'more') {
          setMoreAnchorEl(event.currentTarget);
      }
      setHoveredMenu(menu === hoveredMenu ? null : menu);
  };

    const handleMenuClose = () => {
        setSellAnchorEl(null);
        setMoreAnchorEl(null);
        setHoveredMenu(null);
    };
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
      };

 
      const navigate = (path) => {
        history(path);
        handleMenuClose();
        setDrawerOpen(false);  // Close the drawer after navigation
    };
    const handleSubMenuClick = (path) => {
      history(path);
      handleMenuClose();
  };


    const renderMenuItems = (menu) => {
        const menuItems = {
            sell: [
                { text: 'Phone', icon: <Phone />, path: '/sell/sell-phone' },
                { text: 'Laptop', icon: <Laptop />, path: '/sell/sell-laptop' },
                { text: 'iMac', icon: <DesktopMac />, path: '/sell/sell-imac' },
                { text: 'Tablet', icon: <Tablet />, path: '/sell/sell-tablet' },
              ],
            more: [
                { text: 'Contact Us', icon: <ContactPhone />, path: '/contact' },
                { text: 'Refer & Earn', icon: <Star />, path: '/refer' },
                { text: 'Company', icon: <Business />, path: '/company' },
                { text: 'Terms & Conditions', icon: <Description />, path: '/terms' },
                { text: 'Cookie Policy', icon: <Description />, path: '/cookies-policy' },
                { text: 'Privacy Policy', icon: <Description />, path: '/privacy-policy' },
            ],
        };

        return menuItems[menu].map((item) => (
          <ListItem button key={item.text} onClick={() => handleSubMenuClick(item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText className='menuItems' primary={item.text} />
            </ListItem>
          ));
    };

    return (
        <>
    
    <Toolbar style={{ backgroundColor: '#f7f7f7', justifyContent: 'center',color:"#000",borderBottom:"1px solid #ccc" }}>
              {isMobile ? (
                <>
                  <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    
                  </Typography>
                  <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                    <Box
                      sx={{ width: 250 }}
                      role="presentation"
                      onClick={handleDrawerToggle}
                      onKeyDown={handleDrawerToggle}
                    >
                      <List>
                          <ListItem button onClick={() => navigate('/about')}>
                          <ListItemIcon><InfoIcon /></ListItemIcon>
                          <ListItemText primary="About Us" />

                          </ListItem>
                          <ListItem button onClick={() => navigate('/partner')}>
                              <ListItemIcon><BusinessIcon /></ListItemIcon>
                              <ListItemText primary="Partner With Us" />
                          </ListItem>
                          <ListItem button onClick={(e) => handleMenuToggle(e, 'sell')}>
                              <ListItemIcon><SellIcon /></ListItemIcon>
                              <ListItemText primary="Sell" />
                              {hoveredMenu === 'sell' ? <ArrowDropUp /> : <ArrowDropDown />}
                          </ListItem>
                          {sellAnchorEl && hoveredMenu === 'sell' && (
                              <Box sx={{ pl: 2 }}>
                                  <List  style={{ margin: '0 15px', textTransform: 'capitalize', fontWeight: 'bold', color: '#000' }}>{renderMenuItems('sell')}</List>
                              </Box>
                          )}
                          <ListItem button onClick={(e) => handleMenuToggle(e, 'more')}>
                              <ListItemIcon><MoreHorizIcon /></ListItemIcon>
                              <ListItemText primary="More" />
                              {hoveredMenu === 'more' ? <ArrowDropUp /> : <ArrowDropDown />}
                          </ListItem>
                          {moreAnchorEl && hoveredMenu === 'more' && (
                              <Box sx={{ pl: 2 }}  style={{ margin: '0 15px', textTransform: 'capitalize', fontWeight: 'bold', color: '#000' }}>
                                  <List >{renderMenuItems('more')}</List>
                              </Box>
                          )}
                      </List>
                    </Box>
                  </Drawer>
                </>
              ) : (
                <Grid container spacing={2} alignItems="center" justifyContent={"space-between"}>
                  <Grid item>
                    <Button
                    startIcon={<SellIcon/>}
                    style={{ margin: '0 15px', textTransform: 'capitalize', fontWeight: 'bold', color: '#000' }}
                      color="inherit"
                      onClick={(e) => handleMenuToggle(e, 'sell')}
                    >
                      Sell
                      {hoveredMenu === 'sell' ? <ArrowDropUp /> : <ArrowDropDown />}
                    </Button>
                    <Popover
                      anchorEl={sellAnchorEl}
                      open={Boolean(sellAnchorEl && hoveredMenu === 'sell')}
                      onClose={handleMenuClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Box>
                        <List>
                          {renderMenuItems('sell')}
                        </List>
                      </Box>
                    </Popover>
                  </Grid>
                  <Grid item>
                    <Button  startIcon={<InfoIcon/>} color="inherit" style={{ margin: '0 15px', textTransform: 'capitalize', fontWeight: 'bold', color: '#000' }}
 onClick={() => navigate('/about')}>About Us</Button>
                  </Grid>
                  <Grid item>
                    <Button
                     style={{ margin: '0 15px', textTransform: 'capitalize', fontWeight: 'bold', color: '#000' }}
                    startIcon={<MoreHorizIcon/>}
                      color="inherit"
                      onClick={(e) => handleMenuToggle(e, 'more')}
                    >
                      More
                      {hoveredMenu === 'more' ? <ArrowDropUp /> : <ArrowDropDown />}
                    </Button>
                    <Popover
                      anchorEl={moreAnchorEl}
                      open={Boolean(moreAnchorEl && hoveredMenu === 'more')}
                      onClose={handleMenuClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Box>
                        <List>
                          {renderMenuItems('more')}
                        </List>
                      </Box>
                    </Popover>
                  </Grid>
                  <Grid item>
                    <Button                      style={{ margin: '0 15px', textTransform: 'capitalize', fontWeight: 'bold', color: '#000' }}
 startIcon={<BusinessIcon/>} color="inherit" onClick={() => navigate('/partner')}>Partner With Us</Button>
                  </Grid>
                </Grid>
              )}
            </Toolbar>
        
        </>
      );
};

export default Navbar;
