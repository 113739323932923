import React, { useState,useEffect } from 'react';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Modal} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NoImage from "../../assets/images/Thumbnail.jpg"
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { config } from '../../config';
import Skeleton from '@mui/material/Skeleton';
// import CircularProgress from '@mui/material/CircularProgress';


 
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
  TablePagination,
  Grid,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  Box,  
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  InputBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  Avatar,
  FormHelperText,
  Input

} from '@mui/material';
import {
  Menu as MenuIcon,
  Add as AddIcon,
  Assignment as AssignmentIcon,
  PeopleAlt as PeopleAltIcon,
  Search as SearchIcon,
  TrendingUp as TrendingUpIcon,
  Person as PersonIcon,
  Logout as LogoutIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  TrendingDown
} from '@mui/icons-material';
import * as XLSX from 'xlsx';
import axios from 'axios';

 

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false);
  const[showVendor,setshowVendor] = useState(false);
  const[showExecutive,setshowExecutive] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [openPopup1, setOpenPopup1] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setError] = useState(null);

  const [agent, setAgent] = useState(selectedItem.agent || '');
  // const [agent, setAgent] = useState(selectedItem.agent || '');
  const [imei, setImei] = useState(selectedItem.imei || '');
  const [finalPrice, setFinalPrice] = useState(selectedItem.finalPrice || '');
  const [finalPickupDate, setFinalPickupDate] = useState(selectedItem.finalPickupDate || null);
  const [alternatePhoneNumber, setAlternatePhoneNumber] = useState(selectedItem.alternatePhoneNumber || '');
  const [comment,setComment] = useState('');
  const [idProofDocuments, setIdProofDocuments] = useState({
    aadharFront: selectedItem.idProofDocuments?.aadharFront || null,
    aadharBack: selectedItem.idProofDocuments?.aadharBack || null,
    phoneFront: selectedItem.idProofDocuments?.phoneFront || null,
    phoneBack: selectedItem.idProofDocuments?.phoneBack || null,
    paymentScreenshot: selectedItem.idProofDocuments?.paymentScreenshot || null
  });
const [currentImage, setCurrentImage] = useState(null);
const [editDialogData, seteditDialogData] = useState(false);
const [Proofid,setProofid] = useState(null);
const [completedCount, setCompletedCount] = useState(0);
const [inProgressCount, setInProgressCount] = useState(0);
const [failedCount, setFailedCount] = useState(0);
const [totalCount, setTotalCount] = useState(0);
const[Reason,setReason] = useState(null);
const [file, setFile] = useState(null);
const [vendors, setVendors] = useState([]);
const [vendorDetails, setvendorDetails] = useState([]);
const [executiveDetails, setExecutiveDetails] = useState([]);
const[TableView,setTableView] = useState("Leads");
const[ShowLeads,setShowLeads] = useState(true);
const[ShowVendors,setShowVendors] = useState(false);
const[ShowExecutives,setShowExecutives] = useState(false);

const [VendorFormData, setVendorFormData] = useState({
  vendorId: "",
  name: "",
  gst: "",
  address: "",
  phoneNumber: "",
});

const [ExecutiveFormData, setExecutiveFormData] = useState({
  ExecutiveId: "",
  name: "",
  address: "",
  phoneNumber: "",
  vendorId:""
});
 

  // Fetch the list of vendors from the backend
 
    const fetchVendors = async () => {
      try {
        const response = await axios.post(`${config.Api}vendors`);
        const data = await response.json();
        console.log(data,"VENDOR>>>")
        setVendors(data);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

    const fetchAllExecutives = async () => {
      try {
        const response = await axios.post(`${config.Api}all-executives`);
        const data = response.data;
        console.log(data,"exec>>>")
        setExecutiveDetails(data);
      } catch (error) {
        console.error("Error fetching executives:", error);
      }
    };


    const fetchAllVendors = async () => {
      try {
        const response = await axios.post(`${config.Api}all-vendors`);
        const data = response.data;
        console.log(data,"AllVENDOR>>>")
        setvendorDetails(data);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

  
 

  const handleImageClick = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpenPopup1(true);
  };
  
  const handleCloseImage = () => {
    setOpenPopup1(false);
  };


    const fetchProofData = async (id) => {
    
      // If proofid and selectedItem.ID are present, fetch agent details
      try {
        const response = await axios.post(`${config.Api}get-proof-details/${id}`);
        const data = response.data;
        
        if (data.length > 0) {
          // Assuming you want to set the state for the first proof in the array
          const proof = data[0];
           setAgent(proof.agent || '');
          setImei(proof.imei || '');
          setFinalPrice(proof.finalPrice || '');
          setFinalPickupDate(proof.finalPickupDate || null);
          setAlternatePhoneNumber(proof.alternatePhoneNumber || '');
          setComment(proof.comment || '');
          setProofid(proof._id || null);
         seteditDialogData(true);
         setIdProofDocuments({
          aadharFront: proof.aadharFront || null,
          aadharBack: proof.aadharBack || null,
          phoneFront: proof.phoneFront || null,
          phoneBack: proof.phoneBack || null,
          paymentScreenshot: proof.paymentScreenshot || null
        });
        } else {
          // setError('No proof records found');
          alert("No Records Found")
        }
        
      } catch (err) {
        setError('Failed to fetch data');
        clearFields();
        // Optionally, you can handle any errors here
      }
    
    
    
  };
  

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };


  useEffect(() => {
    fetchAdminData(page);
    fetchAllDashboardData();
    fetchAllVendors();
  }, []);

  useEffect(() => {
    fetchAdminData(page);
  }, [page, rowsPerPage]);


  const handleStatusChange = async (row, newStatus) => {
    const updatedRecord = await updateStatus(row.ID, newStatus);

    if (updatedRecord) {
      // Update the status in the state
      const updatedData = data.map(item =>
        item.ID === row.ID ? { ...item, Status: updatedRecord.Status } : item
      );
      setData(updatedData);
      const completedCount = updatedData.filter(item => item.Status === 'Completed').length;
      const inProgressCount = updatedData.filter(item => item.Status === 'In Progress').length;
      const failedCount = updatedData.filter(item => item.Status === 'Failed').length;
  
      setCompletedCount(completedCount);
      setInProgressCount(inProgressCount);
      setFailedCount(failedCount);
    }// Update the local state
  
   
  };


  const updateStatus = async (uniqueId, newStatus) => {
    setLoading(true)
    try {
      const response = await fetch(`${config.Api}update-status/${uniqueId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update status');
      }
  
      const updatedRecord = await response.json();
      return updatedRecord;
    } catch (error) {
      alert('Error updating status');
      return null;
    }
    finally{
      setLoading(false);
    }
  };
  
  // Function to fetch staff data from backend

   

  // Fetch the Admin Data

  const fetchAdminData = async (page) => {
    setLoading(true);

    try {
      const response = await axios.post(`${config.Api}userpickupdetails`, {
        page,
        limit: rowsPerPage,
      });
      const { records} = response.data; // Correctly access the data
     
      setData(records);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  const fetchAllDashboardData = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${config.Api}userpickupdetails/all`, {});
      const { records, totalRecords } = response.data;
  
      const completed = records.filter(item => item.Status === 'Completed').length;
      const inProgress = records.filter(item => item.Status === 'In Progress').length;
      const failed = records.filter(item => item.Status === 'Failed').length;
  
      setCompletedCount(completed);
      setInProgressCount(inProgress);
      setFailedCount(failed);
      setTotalCount(totalRecords);
      setLoading(false)
       
    } catch (error) {
      alert('Error fetching all data');
      setLoading(false)
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
  fetchAdminData(page);
  fetchAllDashboardData();
  }
  
  


 

 

  

  const handleUpdate = () => {
    const updatedData = data.map((row) => (row.id === editData.id ? editData : row));
    setData(updatedData);
    setEditIndex(-1);
    setEditData({});
    setOpenPopup(false);
  };
   

  const handleInputChange = (e, field) => {
    setEditData({ ...editData, [field]: e.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchAdminData(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
    fetchAdminData(0);
  };
  
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    // localStorage.removeItem('role');
    navigate('/admin-login'); // Redirect to login page after logout
};
  const handleMoreDetails = (item) => {
    console.log(item,"item")
    setSelectedItem(item);
    fetchProofData(item.ID);
    getReason(item.ID);
    setDetailsDialogOpen(true);
  };

  const handleLeadDelete = async (row) => {
    setLoading(true); // Start loading
    try {
      const url = `${config.Api}userpickup-delete/${row?.ID}`;
      const response = await fetch(url, {
        method: "POST",
      });
      if (response.ok) {
        alert('Lead Deleted Successfully!');
      } else {
        alert("Failed to Delete the Lead");
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false); // Stop loading after processing
      fetchAdminData(page);
    }
  };

  const handleVendorDelete = async (row) => {
    console.log(row,"row");
    // return false;
    setLoading(true); // Start loading
    try {
      const url = `${config.Api}vendor-delete/${row?.vendorId}`;
      const response = await fetch(url, {
        method: "POST",
      });
      if (response.ok) {
        alert('Vendor Deleted Successfully!');
      } else {
        alert("Failed to Delete the Vendor");
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false); // Stop loading after processing
      fetchAllVendors();
    }
  };

  const handleExecutiveDelete = async (row) => {
    console.log(row,"e");
    // return;
    setLoading(true); // Start loading
    try {
      const url = `${config.Api}executive-delete/${row?.ExecutiveId}`;
      const response = await fetch(url, {
        method: "POST",
      });
      if (response.ok) {
        alert('Executive Deleted Successfully!');
      } else {
        alert("Failed to Delete the Executive");
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false); // Stop loading after processing
      fetchAllExecutives();
    }
  };
  

  const handleCloseDetailsDialog = () => {
    setDetailsDialogOpen(false);
    setSelectedItem({});
  };

  const getReason = async (uniqueId) => {
    try {
      const response = await fetch(`${config.Api}get-reason/${uniqueId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch Reason');
      }
  
      const data = await response.json();
      setReason(data.reason);
      return data;
    } catch (error) {
      alert('Error fetching status');
      return null;
    }
  };

  const excludeKeys = ['Answers', 'touch','deviceAnswers', 'screenOriginal', 'warranty',"ScreenOptions", "DefectsOptions", "visibleLines","FunctionalOptions","AccOptions","PaymentOption","Status","status","ImageURL"];
  const excludeVendorKeys = ["_id","__v"];



  const renderSection = (title, items) => {
    if (items?.length === 0) return null
    if(title === "Functional Conditions" || title === "Accessories Conditions"){
      return (
        <Grid item xs={6} style={{ marginTop: '16px' }}>
          <Typography variant="h6"><strong>{title}</strong></Typography>
          {items?.map((item, index) => (
            <Grid container key={index} spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1">{item?.label}</Typography>
              </Grid>
            </Grid>
          ))}
          
        </Grid>
      );
    }
    else {
      return (
        <Grid item xs={6} style={{ marginTop: '16px' }}>
          <Typography variant="h6"><strong>{title}</strong></Typography>
          {items?.map((item, index) => (
            <Grid container key={index} spacing={2}>
              {Object.entries(item)?.map(([key, value], idx) => (
                <React.Fragment key={idx}>
                  <Grid item xs={12}>
                    <Typography variant="body1">{value?.text || value?.label}</Typography>
                  </Grid>
                
                </React.Fragment>
              ))}
            </Grid>
          ))}
        </Grid>
      );
    }

   
  };


 // Function to handle PDF generation
const handleGeneratePdf = async () => {
  setLoading(true);
  try {
      const response = await axios.post(`${config.Api}generate-pdf`, selectedItem);
      if (response.status === 200) {
      }
  } catch (error) {
      setError('Error generating PDF');
  } finally {
      setLoading(false);
  }
};


// Function to handle sending email
const handleSendEmail = async () => {
  setLoading(true);
  try {
      const response = await axios.post(`${config.Api}send-email`, { email: selectedItem.Email });
      if (response.status === 200) {
      }
  } catch (error) {
      setError('Error sending email');
  } finally {
      setLoading(false);
  }
};


const handleFormSubmit = async () => {
  // Check if Proofid and selectedItem.ID are valid
  if (!selectedItem.ID) {
    // Show an error message if selectedItem.ID is not valid
    setError("Selected ID must be valid to save changes.");
    return;
  }

  const formData = new FormData();
  formData.append('ID', selectedItem.ID);
  formData.append('email', selectedItem.Email);
  formData.append('agent', agent);
  formData.append('imei', imei);
  formData.append('finalPrice', finalPrice);
  formData.append('finalPickupDate', finalPickupDate);
  formData.append('alternatePhoneNumber', alternatePhoneNumber);
  formData.append('comment', comment);

  // Append files to formData
  if (idProofDocuments.aadharFront) formData.append('aadharFront', idProofDocuments.aadharFront);
  if (idProofDocuments.aadharBack) formData.append('aadharBack', idProofDocuments.aadharBack);
  if (idProofDocuments.phoneFront) formData.append('phoneFront', idProofDocuments.phoneFront);
  if (idProofDocuments.phoneBack) formData.append('phoneBack', idProofDocuments.phoneBack);
  if (idProofDocuments.paymentScreenshot) formData.append('paymentScreenshot', idProofDocuments.paymentScreenshot);
  console.log(idProofDocuments,"idProofDocuments")

  try {
    // Determine the URL and method based on the existence of Proofid
    const url = !Proofid ? `${config.Api}proof-details/${selectedItem.ID}` : `${config.Api}proof-details/${selectedItem.ID}/${Proofid}`;
    const method = !Proofid ? 'POST' : 'POST';

    const response = await axios({
      method,
      url,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.status === 201) {
      const result = response.data;
      if (!Proofid) setProofid(result._id); // Set Proofid if it's a new record
      alert('Data saved successfully');
      handleCloseDetailsDialog();
    }
    else if(response.status === 200){
      const result = response.data;
      if (!Proofid) setProofid(result._id); // Set Proofid if it's a new record
      alert('Data updated successfully');
      handleCloseDetailsDialog();
    } 
    
    else {
      alert('Failed to save data');
    }
  } catch (error) {
    alert('An error occurred while saving data');
  }
};

const clearFields = () => {
  setProofid(null);
  setAgent('');
  setImei('');
  setFinalPrice('');
  setFinalPickupDate(null);
  setAlternatePhoneNumber('');
  setComment('');
  setIdProofDocuments({
    aadharFront: null,
    aadharBack: null,
    phoneFront: null,
    phoneBack: null,
    paymentScreenshot: null
  });
};

const handleImportFileChange = (e) => {
  setFile(e.target.files[0]);
};


const handleFileChange = (e, field) => {
  const file = e.target.files[0];
  if (file) {
    const fileUrl = URL.createObjectURL(file); // Create URL for the file
    setIdProofDocuments(prev => ({
      ...prev,
      [field]: file // Store the file object
    }));
  }
};

const toggleEditMode = () => {
  if (Proofid && selectedItem.ID) {
    // Toggle edit mode only if both Proofid and selectedItem.ID are present
    seteditDialogData(!editDialogData);
  } else {
    // If either Proofid or selectedItem.ID is missing, set to save mode
    seteditDialogData(false);
  }
};


// Filter the records by clicking the cards

const filterByStatus = async (status) => {
 
  try {
    const response = await axios.post(`${config.Api}data?status=${status}`);
    console.log(response,"response")
    setData(response.data);
  } catch (error) {
  }
};

const handleImportClick = async () => {


  const formData = new FormData();
  formData.append('file', file);

  try {
    setLoading(true);
    const response = await axios.post(`${config.Api}save-sheet`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    alert(response.data);
  } catch (error) {
    alert('Failed to import data');
  } finally {
    setLoading(false);
  }
};



// const handleDeleteLeads = async ()=

 

// Inside your Dialog component
<DialogActions>
    <Button onClick={handleCloseDetailsDialog}>Close</Button>
    {/* <Button onClick={handleInvoiceSubmit} color="primary" variant="contained">Submit</Button> */}
</DialogActions>


const handleVendorSubmit = async () => {
  // Add form validations if needed
  if (!VendorFormData.vendorId || !VendorFormData.name || !VendorFormData.gst) {
    alert("Please fill in all required fields!");
    return;
  }

  try {
    // Call the API with form data
    const response = await fetch(`${config.Api}add-vendor`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(VendorFormData),
    });

    if (!response.ok) {
      throw new Error(`Failed to submit data: ${response.statusText}`);
    }

    const result = await response.json();
    alert("Vendor added successfully!");
    console.log(result);

    // Close the dialog and reset the form
    setshowVendor(false);
    setVendorFormData({
      vendorId: "",
      name: "",
      gst: "",
      address: "",
      phoneNumber: "",
    });
  } catch (error) {
    console.error("Error submitting vendor data:", error);
    alert("An error occurred while submitting the form. Please try again.");
  }
};


const handleExecutiveSubmit = async () => {
  // Add form validations if needed
  if (!ExecutiveFormData.ExecutiveId || !ExecutiveFormData.name || !ExecutiveFormData.vendorId) {
    alert("Please fill in all required fields!");
    return;
  }

  try {
    // Call the API with form data
    const response = await fetch(`${config.Api}add-executive`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ExecutiveFormData),
    });

    if (!response.ok) {
      throw new Error(`Failed to submit data: ${response.statusText}`);
    }

    const result = await response.json();
    alert("Executive added successfully!");
    console.log(result);

    // Close the dialog and reset the form
    setshowExecutive(false);
    setExecutiveFormData({
      vendorId: "",
      name: "",
      gst: "",
      address: "",
      phoneNumber: "",
    });
  } catch (error) {
    console.error("Error submitting vendor data:", error);
    alert("An error occurred while submitting the form. Please try again.");
  }
};


const handleVendorChange = (e) => {
  const { name, value } = e.target;
  setVendorFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

const handleExecutiveChange = (e,type) => {
  if(type === "select"){
    fetchVendors();
  }
  const { name, value } = e.target;
  setExecutiveFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

  

  return (
    <Container maxWidth={false}  sx={{ backgroundColor: '#1c1c1c', minHeight: '100vh', py: 4 }}>
     

      <>
     <AppBar position="static">
     <Toolbar>
    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
      <MenuIcon />
    </IconButton>
    <Typography variant="h6" sx={{ flexGrow: 1, fontSize: '1.2rem' }}>
      Admin Dashboard
  <IconButton onClick={handleRefresh} sx={{ ml: 1 }} aria-label="refresh">
    <RefreshIcon style={{color:"white"}}  />
  </IconButton>
  <Button color="secondary" variant="contained" onClick={() => setshowVendor(true)} sx={{ fontSize: '0.7rem' }}>
   <AddIcon fontSize='small'/>  
   Vendor
   </Button>

   <Button color="secondary" variant="contained" onClick={() => setshowExecutive(true)} sx={{ fontSize: '0.7rem' }}>
   <AddIcon fontSize='small'/>  
   Executive
   </Button>
 
    </Typography>

        
   
  
    <Box display="flex" alignItems="end" sx={{ mr: 2 }}>
    <Input type="file" onChange={handleImportFileChange} />
      <Button
       startIcon={<ImportExportIcon/>}
        variant="contained"
        color="primary"
        onClick={handleImportClick}
        disabled={loading || !file}
        style={{ marginTop: 20 }}
      >
        {loading ? <CircularProgress size={24} /> : 'Import and Save Sheet'}
      </Button>
      
    </Box>
      <Avatar sx={{ bgcolor: '#ff5722', mr: 1 }}>A</Avatar>
      <Typography variant="body1" sx={{ fontSize: '1rem', mr: 1 }}>
        Hi, Admin
      </Typography>

     
    
    
    <Button color="secondary" variant="contained" onClick={handleLogout} sx={{ fontSize: '0.7rem' }}>
      <LogoutIcon sx={{ ml: 2,gap:2  }} />
      Logout
    </Button>

    {/* <Button color="secondary" variant="contained" onClick={handleDeleteLeads} sx={{ fontSize: '0.7rem' }} style={{marginLeft:"3px"}}>
      <DeleteIcon sx={{ ml:3}} />
      Delete Leads
    </Button> */}
  </Toolbar>
</AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <List>
          <ListItem button key="Leads" onClick={() => {setPage(0); setDrawerOpen(false);setShowLeads(true);setShowExecutives(false);setShowVendors(false)}}>
            <ListItemIcon><AssignmentIcon /></ListItemIcon>
            <ListItemText primary="Leads" />
          </ListItem>
          <ListItem button key="Leads" onClick={() => {setPage(0); setDrawerOpen(false);setShowLeads(false);setShowExecutives(false);setShowVendors(true);fetchAllVendors()}}>
            <ListItemIcon><PeopleAltIcon /></ListItemIcon>
            <ListItemText primary="Vendors" />
          </ListItem>

          <ListItem button key="Executives" onClick={() => {setPage(0); setDrawerOpen(false);setShowLeads(false);setShowExecutives(true);setShowVendors(false);fetchAllExecutives();}}>
            <ListItemIcon><PeopleAltIcon /></ListItemIcon>
            <ListItemText primary="Executives" />
          </ListItem>
            
        </List>
      </Drawer>

      <Grid container spacing={3}>

      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative',cursor:"pointer" }} onClick={() => fetchAllDashboardData()}>
          <TrendingUpIcon sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: 'blue' }} />
          <Typography variant="h6" gutterBottom>Over All</Typography>
          <Typography variant="h4" gutterBottom>{totalCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative',cursor:"pointer" }} onClick={() => filterByStatus('Completed')}>
          <TrendingUpIcon sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: '#4caf50' }} />
          <Typography variant="h6" gutterBottom>Completed</Typography>
          <Typography variant="h4" gutterBottom>{completedCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative',cursor:"pointer" }} onClick={() => filterByStatus('In Progress')}>
          <TrendingUpIcon sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: '#ffeb3b' }} />
          <Typography variant="h6" gutterBottom>In Progress</Typography>
          <Typography variant="h4" gutterBottom>{inProgressCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative',cursor:"pointer" }} onClick={() => filterByStatus('Failed')}>
          <TrendingDown sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: 'red' }} />
          <Typography variant="h6" gutterBottom>Failed</Typography>
          <Typography variant="h4" gutterBottom>{failedCount}</Typography>
        </Paper>
      </Grid>
     
    </Grid>

      <Paper sx={{ mt: 3, p: 2 }} style={{overflow:"scroll"}}>
   
        <>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              placeholder="Search..."
              variant="outlined"
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              onChange={handleSearch}
               size="small"
            />
          </Grid>
        </Grid>
      
       
       
      
    
      
      
       
       {ShowLeads ?
       
       !data ? ( <CircularProgress sx={{ mt: 2 }} />) : (

        
        <Table sx={{ mt: 2 }}>
        {data && data.length > 0 && (
          <TableHead>
            <TableRow>
              {Object.keys(data[0])
                .filter(key => !excludeKeys.includes(key))
                .map(key => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
             <TableCell>Status</TableCell>
             <TableCell>Actions</TableCell>

            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data
            .filter((row) =>
              Object.values(row).some(
                (value) =>
                  typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
              )
            ).map((row, index) => (
              <TableRow key={row.id}>
                {Object.keys(row)
                  .filter(key => !excludeKeys.includes(key))
                  .map(key => (
                    <TableCell key={key}>{typeof row[key] === 'object' ? JSON.stringify(row[key]) : row[key]}</TableCell>
                  ))}
                     <TableCell>
                      <Select
                        value={row.Status}
                        onChange={(e) => handleStatusChange(row, e.target.value)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Status' }}
                      >
                        <MenuItem value="None"><em>None</em></MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                        <MenuItem value="Failed">Failed</MenuItem>
                        <MenuItem value="In Progress">In Progress</MenuItem>
                      </Select>
                   </TableCell>
                <TableCell style={{display:"flex"}}>
                  <IconButton onClick={() => handleMoreDetails(row)}>
                    <MoreVertIcon />
                    
                  </IconButton>
                  <IconButton onClick={() => handleLeadDelete(row)}>
                    <DeleteIcon />
                    
                  </IconButton>
                 </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

       ) : ShowVendors ? (

        <Table sx={{ mt: 2 }}>
{vendorDetails && vendorDetails.length > 0 && (
  <TableHead>
    <TableRow>
      {Object.keys(vendorDetails[0])
        .filter(key => !excludeVendorKeys.includes(key))
        .map(key => (
          <TableCell key={key}>{key}</TableCell>
        ))}
     {/* <TableCell>Status</TableCell>
     <TableCell>Actions</TableCell> */}

    </TableRow>
  </TableHead>
)}
<TableBody>
  {vendorDetails
    .filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    ).map((row, index) => (
      <TableRow key={row.id}>
        {Object.keys(row)
          .filter(key => !excludeVendorKeys.includes(key))
          .map(key => (
            <TableCell key={key}>{typeof row[key] === 'object' ? JSON.stringify(row[key]) : row[key]}</TableCell>
          ))}
             {/* <TableCell>
              <Select
                value={row.Status}
                onChange={(e) => handleStatusChange(row, e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Status' }}
              >
                <MenuItem value="None"><em>None</em></MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
              </Select>
           </TableCell> */}
        <TableCell style={{display:"flex"}}>
          <IconButton onClick={() => handleMoreDetails(row)}>
            <MoreVertIcon />
            
          </IconButton>
          <IconButton onClick={() => handleVendorDelete(row)}>
            <DeleteIcon />
            
          </IconButton>
         </TableCell>
      </TableRow>
    ))}
</TableBody>
</Table>

       ) :
       (
        <Table sx={{ mt: 2 }}>
{executiveDetails && executiveDetails.length > 0 && (
  <TableHead>
    <TableRow>
      {Object.keys(executiveDetails[0])
        .filter(key => !excludeVendorKeys.includes(key))
        .map(key => (
          <TableCell key={key}>{key}</TableCell>
        ))}
     {/* <TableCell>Status</TableCell>
     <TableCell>Actions</TableCell> */}

    </TableRow>
  </TableHead>
)}
<TableBody>
  {executiveDetails
    .filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    ).map((row, index) => (
      <TableRow key={row.id}>
        {Object.keys(row)
          .filter(key => !excludeVendorKeys.includes(key))
          .map(key => (
            <TableCell key={key}>{typeof row[key] === 'object' ? JSON.stringify(row[key]) : row[key]}</TableCell>
          ))}
             {/* <TableCell>
              <Select
                value={row.Status}
                onChange={(e) => handleStatusChange(row, e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Status' }}
              >
                <MenuItem value="None"><em>None</em></MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
              </Select>
           </TableCell> */}
        <TableCell style={{display:"flex"}}>
          <IconButton onClick={() => handleMoreDetails(row)}>
            <MoreVertIcon />
            
          </IconButton>
          <IconButton onClick={() => handleExecutiveDelete(row)}>
            <DeleteIcon />
            
          </IconButton>
         </TableCell>
      </TableRow>
    ))}
</TableBody>
</Table>
       )
       
       
       }  


       


       
 
        
      
                </>
   


<TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={totalCount} // Use the totalCount state here
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      

</Paper>


<Dialog open={showVendor} onClose={() => setshowVendor(false)}>
      <DialogTitle>Add Vendor Details</DialogTitle>
      <DialogContent>
        <TextField
          name="vendorId"
          label="Vendor ID"
          variant="outlined"
          fullWidth
          margin="normal"
          value={VendorFormData.vendorId}
          onChange={handleVendorChange}
          required
        />
        <TextField
          name="name"
          label="Name (as per GST)"
          variant="outlined"
          fullWidth
          margin="normal"
          value={VendorFormData.name}
          onChange={handleVendorChange}
          required
        />
        <TextField
          name="gst"
          label="GST"
          variant="outlined"
          fullWidth
          margin="normal"
          value={VendorFormData.gst}
          onChange={handleVendorChange}
          required
        />
        <TextField
          name="address"
          label="Address"
          variant="outlined"
          fullWidth
          margin="normal"
          value={VendorFormData.address}
          onChange={handleVendorChange}
        />
        <TextField
          name="phoneNumber"
          label="Phone Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={VendorFormData.phoneNumber}
          onChange={handleVendorChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setshowVendor(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleVendorSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={showExecutive} onClose={() => setshowExecutive(false)}>
      <DialogTitle>Add Executive Details</DialogTitle>
      <DialogContent>
        <TextField
          name="ExecutiveId"
          label="Executive ID"
          variant="outlined"
          fullWidth
          margin="normal"
          value={ExecutiveFormData.ExecutiveId}
          onChange={handleExecutiveChange}
          required
        />
        <TextField
          name="name"
          label="Executive Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={ExecutiveFormData.name}
          onChange={handleExecutiveChange}
          required
        />

<FormControl variant="outlined" fullWidth margin="normal" required>
          <InputLabel id="vendorId-label">Vendor</InputLabel>
          <Select
            name="vendorId"
            labelId="vendorId-label"
            value={ExecutiveFormData.vendorId}
            onChange={(e) => handleExecutiveChange(e, "select")}
    onOpen={fetchVendors} // Trigger API call when dropdown is opened
            label="Vendor"
          >
            {vendors.map((vendor) => (
              <MenuItem key={vendor.vendorId} value={vendor.vendorId}>
                {vendor.name} ({vendor.vendorId})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      
        <TextField
          name="address"
          label="Address"
          variant="outlined"
          fullWidth
          margin="normal"
          value={ExecutiveFormData.address}
          onChange={handleExecutiveChange}
        />
        <TextField
          name="phoneNumber"
          label="Phone Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={ExecutiveFormData.phoneNumber}
          onChange={handleExecutiveChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setshowExecutive(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleExecutiveSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>


      <Dialog open={detailsDialogOpen} onClose={handleCloseDetailsDialog} fullWidth maxWidth="md">
      {Reason && (<p style={{color:"red",justifyContent:"center",alignItems:"center",fontSize:"14px",display:"flex"}}>Reason For Cancellation : {Reason}</p>)}
<DialogTitle>Invoice Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1"><strong>ID:</strong> {selectedItem["ID"]}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1"><strong>Brand and Model:</strong> {`${selectedItem["Brand Name"]} ${selectedItem.Model} (${selectedItem["formattedVariant"]})`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1"><strong>Device Condition:</strong></Typography>
            <Typography variant="subtitle1"><strong>Can you able to make and receive calls?:</strong> {selectedItem?.Answers?.calls}</Typography>
            <Typography variant="subtitle1"><strong>Touch screen working properly on your mobile?:</strong> {selectedItem?.Answers?.touch}</Typography>
            <Typography variant="subtitle1"><strong>The display on your phone is it original?:</strong> {selectedItem?.Answers?.screenOriginal}</Typography>
            <Typography variant="subtitle1"><strong>Do You Have a Brand Warranty On Your Phone?:</strong> {selectedItem?.Answers?.warranty}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1"><strong>Estimated Price:</strong> {selectedItem.QuotedPrice}</Typography>
          </Grid>
          {selectedItem.ScreenOptions && renderSection('Screen Conditions', selectedItem.ScreenOptions)}
          {selectedItem.DefectsOptions && renderSection('Defects Conditions', selectedItem.DefectsOptions)}
          {selectedItem.FunctionalOptions && renderSection('Functional Conditions', selectedItem.FunctionalOptions[0]?.functionalProblems)}
          {selectedItem.AccOptions && renderSection('Accessories Conditions', selectedItem.AccOptions)}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Pick up Date:</strong> {selectedItem["Pickup Date"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Name:</strong> {selectedItem["Name"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Email:</strong> {selectedItem["Email"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Phone No:</strong> {selectedItem["PhoneNumber"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Address:</strong> {selectedItem["Address"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>PinCode:</strong> {selectedItem["pinCode"]}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Agent:</strong></Typography>
          <TextField
            label="Agent"
            fullWidth
            value={agent}
            onChange={(e) => setAgent(e.target.value)}
           />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>IMEI:</strong></Typography>
          <TextField
            label="IMEI"
            fullWidth
            value={imei}
            onChange={(e) => setImei(e.target.value)}
           />
        </Grid>
          <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Final Price:</strong></Typography>
          <TextField
            label="Final Price"
            fullWidth
            value={finalPrice}
            onChange={(e) => setFinalPrice(e.target.value)}
             InputProps={{
              endAdornment: (
                <IconButton onClick={toggleEditMode}>
                  <EditIcon />
                </IconButton>
              )
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Final Pickup Date:</strong></Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Final Pickup Date"
              value={finalPickupDate}
              onChange={(date) => setFinalPickupDate(date)}
               renderInput={(params) => <TextField fullWidth {...params} disabled={!editDialogData} />}
            />
          </LocalizationProvider>
          {error?.pickupDate && <FormHelperText error>{error?.pickupDate}</FormHelperText>}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Alternate Phone Number:</strong></Typography>
          <TextField
            label="Alternate Phone Number"
            fullWidth
            value={alternatePhoneNumber}
            onChange={(e) => setAlternatePhoneNumber(e.target.value)}
             
          />
        </Grid>

        <Grid item xs={6}>
        <Typography variant="subtitle1"><strong>ID Proof Documents:</strong></Typography>
        <Typography variant="subtitle1"><strong>Aadhar Image:</strong></Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box>
            <img
              src={idProofDocuments.aadharFront ||  NoImage}
              alt="Aadhar Front"
              width="50"
              height="50"
              onClick={() => idProofDocuments.aadharFront && handleImageClick(idProofDocuments.aadharFront)}
              style={{ cursor: idProofDocuments.aadharFront ? 'pointer' : 'default', marginBottom: '8px' }}
            />
            <TextField
              type="file"
              onChange={(e) => handleFileChange(e, 'aadharFront')}
              // disabled={!!idProofDocuments.aadharFront} // Disable if an image is present
            />
          </Box>
          <Box>
            <img
              src={idProofDocuments.aadharBack || NoImage}
              alt="Aadhar Back"
              width="50"
              height="50"
              onClick={() => idProofDocuments.aadharBack && handleImageClick(idProofDocuments.aadharBack)}
              style={{ cursor: idProofDocuments.aadharBack ? 'pointer' : 'default', marginBottom: '8px' }}
            />
            <TextField
              type="file"
              onChange={(e) => handleFileChange(e, 'aadharBack')}
              // disabled={!!idProofDocuments.aadharBack} // Disable if an image is present
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1"><strong>Phone Image:</strong></Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box>
            <img
              src={idProofDocuments.phoneFront || NoImage}
              alt="Phone Front"
              width="50"
              height="50"
              onClick={() => idProofDocuments.phoneFront && handleImageClick(idProofDocuments.phoneFront)}
              style={{ cursor: idProofDocuments.phoneFront ? 'pointer' : 'default', marginBottom: '8px' }}
            />
            <TextField
              type="file"
              onChange={(e) => handleFileChange(e, 'phoneFront')}
              // disabled={!!idProofDocuments.phoneFront} // Disable if an image is present
            />
          </Box>
          <Box>
            <img
              src={idProofDocuments.phoneBack || NoImage}
              alt="Phone Back"
              width="50"
              height="50"
              onClick={() => idProofDocuments.phoneBack && handleImageClick(idProofDocuments.phoneBack)}
              style={{ cursor: idProofDocuments.phoneBack ? 'pointer' : 'default', marginBottom: '8px' }}
            />
            <TextField
              type="file"
              onChange={(e) => handleFileChange(e, 'phoneBack')}
              // disabled={!!idProofDocuments.phoneBack} // Disable if an image is present
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1"><strong>Payment Screenshot:</strong></Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box>
            <img
              src={idProofDocuments.paymentScreenshot || NoImage}
              alt="Payment Screenshot"
              width="50"
              height="50"
              onClick={() => idProofDocuments.paymentScreenshot && handleImageClick(idProofDocuments.paymentScreenshot)}
              style={{ cursor: idProofDocuments.paymentScreenshot ? 'pointer' : 'default', marginBottom: '8px' }}
            />
            <TextField
              type="file"
              onChange={(e) => handleFileChange(e, 'paymentScreenshot')}
              // disabled={!!idProofDocuments.paymentScreenshot} // Disable if an image is present
            />
          </Box>
        </Box>
      </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Comment:</strong></Typography>
          <TextField
            label="comment"
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
             
          />
        </Grid>
        <Grid container spacing={2}>
    
    </Grid>


      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDetailsDialog} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} color="primary" disabled={loading}>
        {(Proofid && selectedItem.ID) ? 'Update Proof' : 'Save Proof'}
        </Button>
        <Button onClick={handleGeneratePdf} color="primary" disabled={loading}>
          {loading ? 'Generating PDF...' : 'Generate PDF'}
        </Button>
        <Button onClick={handleSendEmail} color="primary" disabled={loading}>
        {loading ? 'Sending Email...' : 'Send Email'}
    </Button>
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
      </DialogActions>
    </Dialog>

      
      <Dialog open={openPopup} onClose={() => setOpenPopup(false)} fullWidth maxWidth="sm">
        <DialogTitle>Edit Data</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {Object.keys(editData).map((key) => (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  fullWidth
                  label={key}
                  variant="outlined"
                  value={editData[key]}
                  onChange={(e) => handleInputChange(e, key)}
                  multiline={typeof editData[key] === 'object'}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPopup(false)}>Cancel</Button>
          <Button onClick={handleUpdate}>Update</Button>
        </DialogActions>
      </Dialog>


      <Modal open={openPopup1} onClose={handleCloseImage}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            bgcolor: 'background.paper',
            p: 4,
            outline: 'none',
            position: 'relative'
          }}
        >
          <IconButton
            onClick={handleCloseImage}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {currentImage && <img src={currentImage} alt="Popup" style={{ maxHeight: '90%', maxWidth: '90%' }} />}
        </Box>
      </Modal>
      </>
    
    </Container>
  );
};

export default AdminDashboard;
