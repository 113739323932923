import React, { useState } from 'react';
import { Container, Grid, Typography, Button, Snackbar, Box,useMediaQuery, Paper } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Functional from './Functional';
import MuiAlert from '@mui/material/Alert';

import Screen from './Screen';
import image1 from '../../assets/images/10.jpg';
import image2 from '../../assets/images/12.jpg';
import image3 from '../../assets/images/14.jpg';
import image4 from '../../assets/images/15.jpg';
import image5 from '../../assets/images/17.jpg';
import image6 from '../../assets/images/4.png';
import Image13 from '../../assets/images/Image13.jpeg';
import DeviceEvaluation from '../commonForms/DeviceEvaluation';

import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ';


const Defects = ({ selectedModel,  deviceAnswers,rowDetails,formattedVariant,currentValue,warrantyStatus,goBack,answers,FixedPrice,ScreenselectedOptions,userLogDetails,onFormData}) => {
  // console.log(currentValue,"DEFECTS....")
  const [selectedOptions, setSelectedOptions] = useState({
    overallCondition : null,
    bodyDefects: null,

  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [showFunctional, setShowFunctional] = useState(false);
  const [showDefects, setShowDefects] = useState(true);
  const [showScreen, setShowScreen] = useState(false);
  const[DefCurrValue,setDefCurrValue] = useState(currentValue);
  const[showWarrantyPage,setshowWarrantyPage] = useState(true);


  // Warranty Deduction 






  const handleSelection = (categoryId, option) => {
    // console.log(selectedOptions,"current ")

    setSelectedOptions((prev) => ({
      ...prev,
      [categoryId]: prev[categoryId] === option ? null : option,
    }));
  };

  const categories = [
    {
      id: 'overallCondition',
      title: "Phone's Overall Condition",
      options: [
        { text: 'Like New - Zero Scratches, Zero Dents, Flawless', image: image1,  deduction: rowDetails['Like New - Zero Scratches, Zero Dents, Flawless'] },
        { text: 'Good-1-2 scaraches,minor dents,no crack', image: image2,deduction: rowDetails['Good-1-2 scaraches,minor dents,no crack ']},
        { text: 'More than 2 Scratches and Major dents', image: image6,deduction: rowDetails['More than 2 Scratches,Major dents'] },
        { text: 'Average:-major scraches, major dents,panel crackes, loose screen gap', image: image3 ,deduction: rowDetails[' Average:-major scraches, major dents,panel crackes, loose screen gap']},
        { text: 'Below Average:-Heavy scraches,Heavy dents and phone bent', image: image4 ,deduction: rowDetails['Below Average:-Heavy scraches,Heavy dents and phone bent']},
      ],
    },
    {
      id: 'bodyDefects',
      title: 'Device Body Defects',
      options: [
        { text: 'Phone Bent With Panel Crack', image: image5,deduction: rowDetails['Phone Bent With Panel Crack'] },
        { text: 'Only Panel Broken', image: image5 ,deduction: rowDetails['Only Panel Broken']},
        { text: 'Loose Screen Gap', image: image4 ,deduction: rowDetails['Loose Screen Gap']},
        { text: 'Phone Bent', image: Image13 ,deduction: rowDetails['Phone Bent ']},
        { text: 'No Body Defects', image: image1,deduction: rowDetails['No body defect']  },
 
      ],
    },
  ];

  const handleContinue = () => {
    const allCategoriesSelected = categories.every((category) => selectedOptions[category.id]);
    if (!allCategoriesSelected) {
      setAlertOpen(true);
      return;
    }
    else {
      let newValue = DefCurrValue;
     if (!FixedPrice) {
        const deductions = Object.values(selectedOptions).map(option => option.deduction);
        // console.log(deductions,"deductions")
        const maxDeduction = Math.min(...deductions);
        // console.log(maxDeduction, "maxDed");
  
      
  
        newValue += maxDeduction;
        setDefCurrValue(newValue);
        // console.log(newValue, "New Value after deduction");
      }
  
      setShowFunctional(true);
      setShowDefects(false);
    }
    
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  

  const handleFormData = (data) => {
    // console.log("Defects:", data);
    onFormData(data)
    // setFormData(data);
  };
   

  return (
    <>
    <Container>
      {showDefects && (
        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12} md={7}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 4 }}>
          <Typography
                  variant="h6"
                  className="conditionsheading"
                  sx={{
                    marginBottom: 2,
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >Select Body Defects Of Your Device</Typography>
          <Box
       sx={{
        // position: isMobile ? 'fixed' : 'static',
        bottom: isMobile ? 0 : 'auto',
        height: isMobile ? 'calc(100vh - 70px)' : 'auto', // Set height for mobile screens
        overflowY: isMobile ? 'auto' : 'initial', // Allow scrolling for mobile
        // paddingBottom: { xs: '80px', md: '0' },
        width: '100%',
        p: 2,

                // Hide scrollbar (cross-browser solution)
    '&::-webkit-scrollbar': {
      display: 'none', // Hide scrollbar for WebKit browsers
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent', // Ensure it's completely transparent
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent', // Hide the scrollbar track
    },
    scrollbarWidth: 'none',
      }}
    >
          {categories.map((category) => (
              <Category
                key={category.id}
                categoryId={category.id}
                title={category.title}
                options={category.options}
                selectedOption={selectedOptions[category.id]}
                onSelection={handleSelection}
              />
            ))}
             </Box>
                 
       
          
          <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#4caf50',
                      color: 'white',
                      padding: isMobile ? '8px 16px' : '12px 24px',
                      fontSize: isMobile ? '0.9rem' : '1.2rem',
                      borderRadius: '8px',
                      width: { xs: '100%', md: 'auto' }, // Full width on mobile, auto on larger screens
                      marginBottom: { xs: '10px', md: '0' },
                      '&:hover': {
                        backgroundColor: '#45a049',
                      },
                    }}
                    onClick={handleContinue}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Continue
                  </Button>
            {/* </Box> */}
            <Snackbar 
            open={alertOpen} 
            autoHideDuration={6000} 
            onClose={handleAlertClose}
            >
              <MuiAlert 
              elevation={6}
               variant="filled"
                onClose={handleAlertClose} 
                severity="error"
                
                >
                Please select options for all categories.
              </MuiAlert>
            </Snackbar>
            </Paper>
          </Grid>
          {!isMobile && (          
            <Grid item xs={12} md={5}>
            <DeviceEvaluation selectedModel={selectedModel} deviceAnswers={{ ...deviceAnswers, ...selectedOptions }}  formattedVariant={formattedVariant} answers={answers}/>
          </Grid>
          )} 
        </Grid>
      )}

      

      {showFunctional && (
         
          <Functional 
          selectedModel={selectedModel} 
          formattedVariant={formattedVariant}
           currentValue={DefCurrValue}
            warrantyStatus={warrantyStatus}
             rowDetails={rowDetails}
              answers={answers}
               FixedPrice={FixedPrice}
                showWarrantyPage={showWarrantyPage}
                 deviceAnswers={{ ...deviceAnswers, ...selectedOptions }} 
                 ScreenselectedOptions={ScreenselectedOptions} 
                 DefectsselectedOptions={selectedOptions} 
                 userLogDetails={userLogDetails}
                 onFormData={handleFormData}

                 
                 />
        
      )}



    </Container>

{!isMobile && (
  <>
<FAQ  />
<Footer />
</>
)}
</>
  );
};

const Category = ({ categoryId, title, options, selectedOption, onSelection }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <Typography variant="h6"  className='conditionssubheading'>{title}</Typography>
      <Grid container spacing={2}>        
        {options.map((option) => (
          <Item
            key={option.text}
            categoryId={categoryId}
            option={option}
            isSelected={selectedOption?.text === option.text}
            onClick={() => onSelection(categoryId, option)}
          />
        ))}
      </Grid>
    </div>
  );
};

const Item = ({ option, isSelected, onClick }) => {
  return (
    <Grid item xs={6} sm={6} md={4}>
      <Box
        onClick={onClick}
        sx={{
          cursor: 'pointer',
          border: isSelected ? '2px solid green' : '1px solid #ddd',
          borderRadius: '12px',
          overflow: 'hidden',
          textAlign: 'center',
          boxShadow: isSelected ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          },
          height: '200px', // Set a fixed height for uniformity
          // width:'150px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <img
          src={option.image}
          alt={option.text}
          style={{
            width: '100%',
            height: '120px', // Adjust height to balance the overall layout
            objectFit: 'contain',
            borderBottom: '1px solid #ddd',
            padding:"10%"
          }}
        />
        <Typography
          variant="subtitle1"
          sx={{
            padding: '12px',
            fontSize: '10px',
            fontWeight: 'bold',
            color: isSelected ? 'green' : '#333',
            flexGrow: 1, // Allows text to take up space within the box
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {option.text}
        </Typography>
      </Box>
    </Grid>
  );
};


export default Defects;
