// src/features/auth/wrapperSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rowDetails: [],
};

const wrapperSlice = createSlice({
  name: 'wrapper',
  initialState,
  reducers: {
    handleMount: (state, action) => {
        console.log("state",state,"action",action)
      state.rowDetails = action.payload;
    },
    handlePickupDetails: (state, action) => {
      console.log("state",state,"action",action)
    state.handlePickupDetails = action.payload;
  },
  },
});

export const { handleMount,handlePickupDetails } = wrapperSlice.actions;
export default wrapperSlice.reducer;
