import React,{useState} from 'react';
import { Container, TextField, Button, Grid, MenuItem, Typography, Box, Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import Confirmation from  "./Confirmation";
import PaymentMethodForm from './Payment';

const states = [
  'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 
  'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 
  'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 
  'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 
  'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 
  'Uttar Pradesh', 'Uttarakhand', 'West Bengal'
];

const BillingAddressForm = ({selectedModel,  deviceAnswers,formattedVariant,warrantyStatus,goBack,currentValue,rowDetails,answers}) => {

  const [currentPage, setCurrentPage] = useState('Billing Adress');

  const handleContinue = () => {
    if (currentPage === 'Billing Adress') {
      setCurrentPage('confirmation');
    }
  };

  const handleBack = () => {
    if (currentPage === 'Billing Adress') {
      setCurrentPage('PaymentMethod');
    }
  };
  if(currentPage == "PaymentMethod"){
    return <PaymentMethodForm />
  }

  if(currentPage == "confirmation"){
    return <Confirmation selectedModel={selectedModel} formattedVariant={formattedVariant} currentValue={currentValue} warrantyStatus={warrantyStatus} rowDetails={rowDetails} answers={answers}/>
  }
  return (
      <>
        {currentPage === "Billing Adress" && (
          <div>
  <Grid container spacing={2}>
  <Grid item xs={12} md={6}>
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" align="center" gutterBottom>
          Billing Address
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="First Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="Last Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Email Address"
              variant="outlined"
              type="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Address"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="City"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              select
              label="State"
              variant="outlined"
            >
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Pincode"
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Phone"
              variant="outlined"
              type="tel"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Alternate Phone"
              variant="outlined"
              type="tel"
            />
          </Grid>
         
        </Grid>
      </CardContent>
    </Card>
  </Grid>
  <Grid item xs={12} md={6}>
    <Card variant="outlined">
      <CardHeader
        title="Apple iPhone SE 2022"
      />
      <CardMedia
        component="img"
        image="https://via.placeholder.com/150"
        alt="Apple iPhone SE 2022"
        style={{ height: 150 }}
      />
      <CardContent>
        <Typography variant="body1">4GB / 64GB</Typography>
        <Typography variant="body2" color="textSecondary">
          Is your device turned on? Yes
          <br />
          Original Phone Box: Yes
          <br />
          Original Charger: Yes
          <br />
          Original Invoice: Yes
          <br />
          Please select the age of your device: More than 11 Months old
          <br />
          Please select the Phone condition: Like New - Zero Scratches, Zero Dents, Flawless
        </Typography>
      </CardContent>
    </Card>
  </Grid>
</Grid>
<Box sx={{ mt: 4 }}>
{/* <Button variant="contained" onClick={handleBack}>
 Back
</Button> */}
<Button variant="contained"  onClick={handleContinue}       
sx={{ mt: 4, backgroundColor: 'lightgreen', color: 'white' }}
>
 Continue
</Button>
</Box>
</div>

        )}
        </>
    
      
  );
};

export default BillingAddressForm;
