import React, { useState } from 'react';
import {
  TextField, Button, Container, Typography, CircularProgress, Alert, InputAdornment, IconButton
} from '@mui/material';
import { Email, Lock } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {config} from "../../config"

const LoginForm = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { email, password } = formData;
    if (!email || !password) {
      setError('All fields are required');
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email');
      return false;
    }
    if (password.length < 6) {
      setError('Password must be at least 6 characters');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await fetch(`${config.Api}admin-login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
        credentials: 'include', // To include cookies
      });

      const result = await response.json();
      setLoading(false);

      if (response.ok) {
        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwicm9sZSI6ImFkbWluIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';  
        const role = 'admin'; // Replace with real role from server response
        // Save token and role in localStorage
        localStorage.setItem('authToken', token);
    localStorage.setItem('role', role);
    setSuccess('Login successful!');
    alert("Login successful!")
    navigate('/admin-Logged');
        // alert("Login SuccessFully");
        // navigate("/admin");
        // alert(result.message);

        // Save the token and role in localStorage or cookies
       

        // Redirect based on role
        // if (result.role === 'admin') {
        //      alert("Login SuccessFully");
        //   navigate('/admin');
        // } else if (result.role === 'staff') {
        //   navigate('/staff');
        // }
      } else {
        setError(result.message || 'Login failed');
        alert(result.error);
       return false;
        
      }
    } catch (error) {
      setLoading(false);
      setError('An error occurred. Please try again.');
    }
  };

  const handleRegisterRedirect = () => {
    navigate('/admin-register');
  };

  return (
    <Container
      maxWidth="xs"
      style={{
        background: 'linear-gradient(135deg, #1e3c72 30%, #2a5298 90%)',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: '50px',
        color: '#fff'
      }}
    >
      <Typography variant="h4" gutterBottom style={{ textAlign: 'center', color: '#fff' }}>
        Login
      </Typography>
      {error && <Alert severity="error" style={{ marginBottom: 20 }}>{error}</Alert>}
      {success && <Alert severity="success" style={{ marginBottom: 20 }}>{success}</Alert>}
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
            style: { color: '#fff' }
          }}
          InputLabelProps={{
            style: { color: '#fff' }
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
            style: { color: '#fff' }
          }}
          InputLabelProps={{
            style: { color: '#fff' }
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          style={{ marginTop: 20 }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
        </Button>
      </form>
      {/* <Typography variant="body2" style={{ textAlign: 'center', marginTop: 20, color: '#fff' }}>
        Not registered yet?{' '}
        <Button color="secondary" onClick={handleRegisterRedirect}>
          Register
        </Button>
      </Typography> */}
    </Container>
  );
};

export default LoginForm;
