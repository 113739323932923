import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

const CancelOrderDialog = ({ open, onClose, onConfirm }) => {
  const [reason, setReason] = useState('');

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm(reason);

  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Cancel Order</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select the reason for canceling the order:
        </DialogContentText>
        <FormControl component="fieldset">
          <RadioGroup aria-label="reason" name="reason" value={reason} onChange={handleReasonChange}>
            <FormControlLabel value="Device is still under EMI" control={<Radio />} label="Device is still under EMI" />
            <FormControlLabel value="Required documents not available" control={<Radio />} label="Required documents not available" />
            <FormControlLabel value="No one from Cash on Spot contacted me" control={<Radio />} label="No one from Cash on Spot contacted me" />
            <FormControlLabel value="Sold it in my network" control={<Radio />} label="Sold it in my network" />
            <FormControlLabel value="Got a better price" control={<Radio />} label="Got a better price" />
            <FormControlLabel value="Would like to reschedule" control={<Radio />} label="Would like to reschedule" />
            <FormControlLabel value="Others" control={<Radio />} label="Others" />
            <FormControlLabel value="Not interested, was just exploring the option" control={<Radio />} label="Not interested, was just exploring the option" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">No, do not cancel</Button>
        <Button onClick={handleConfirm} color="secondary" disabled={!reason}>Yes, cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelOrderDialog;
