import React from 'react';
import { AppBar, Toolbar, Typography,  Button, Box, Paper, Container, Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { AssignmentTurnedIn, Today, AccountCircle, Edit, Delete, Dashboard, ExitToApp, TrendingDown } from '@mui/icons-material'; // Added Dashboard and ExitToApp icons
// import {
//   Container,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   TextField,
//   CircularProgress,
//   TablePagination,
//   Grid,
 
//   AppBar,
//   Toolbar,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Dialog,
//   Box,  
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Divider,
//   InputBase,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   useMediaQuery,
//   useTheme,
//   Avatar 

// } from '@mui/material';
import {
  Menu as MenuIcon,
  Assignment as AssignmentIcon,
  PeopleAlt as PeopleAltIcon,
  Search as SearchIcon,
  TrendingUp as TrendingUpIcon,
  Person as PersonIcon,
  Logout as LogoutIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: '#333',
  },
  container: {
    paddingTop: "theme.spacing(4)",
    paddingBottom: "theme.spacing(4)",
    backgroundColor: '#222', // Dark background color
    minHeight: '100vh', // Ensure full height
    marginTop: '1%'
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconButton: {
    color: "theme.palette.primary.main",
  },
  tableCard: {
    marginBottom: "theme.spacing(3)",
  },
  tableContainer: {
    maxHeight: 440,
  },
}));

const StaffDashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [leads, setLeads] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedLead, setSelectedLead] = React.useState(null);

  const handleLogout = async () => {
    try {
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      alert('Failed to logout.');
    }
  };

  const fetchLeads = async () => {
    setLoading(true);
    // Simulate API call
    try {
      const mockedData = [
        { id: 1, name: 'Lead 1', address: 'Address 1', email: 'lead1@example.com', pickupDate: '2024-07-15', pickupTime: '10:00 AM', status: 'Assigned' },
        { id: 2, name: 'Lead 2', address: 'Address 2', email: 'lead2@example.com', pickupDate: '2024-07-16', pickupTime: '11:00 AM', status: 'Completed' },
        { id: 3, name: 'Lead 3', address: 'Address 3', email: 'lead3@example.com', pickupDate: '2024-07-17', pickupTime: '12:00 PM', status: 'Assigned' },
      ];
      setLeads(mockedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching leads:', error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchLeads();
  }, []);

  const handleEdit = (lead) => {
    setSelectedLead(lead);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedLead(null);
  };

  const handleSaveLead = () => {
    setOpenDialog(false);
    setSelectedLead(null);
    // For demonstration, update leads state mock
    const updatedLeads = leads.map(lead => {
      if (lead.id === selectedLead.id) {
        return selectedLead; // Replace with updated lead from dialog form
      }
      return lead;
    });
    setLeads(updatedLeads);
  };

  const handleDeleteLead = (leadId) => {
    const updatedLeads = leads.filter(lead => lead.id !== leadId);
    setLeads(updatedLeads);
  };

  const totalLeads = "2300";
  const todaysLeads = "30"

  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Dashboard />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: 1 }}>
            Staff Dashboard
          </Typography>
          <Button color="inherit" onClick={handleLogout} endIcon={<ExitToApp />}>Logout</Button>
        </Toolbar>
      </AppBar>

      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative' }}>
        <PersonIcon sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: '#ffeb3b' }} />
        <Typography variant="h6" gutterBottom>Total Leads</Typography>
        <Typography variant="h4" gutterBottom>{totalLeads}</Typography>
      </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative' }}>
              <TrendingUpIcon sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: '#ffeb3b' }} />
              <Typography variant="h6" gutterBottom>Todays Leads</Typography>
              <Typography variant="h4" gutterBottom>{todaysLeads}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative' }}>
              <PersonIcon sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: '#ffeb3b' }} />
              <Typography variant="h6" gutterBottom>Leads Completed</Typography>
              <Typography variant="h4" gutterBottom>{"2000"}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative' }}>
              <TrendingDown sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: '#ffeb3b' }} />
              <Typography variant="h6" gutterBottom>Leads Failed</Typography>
              <Typography variant="h4" gutterBottom>{"2000"}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Card className={classes.tableCard}>
              <CardContent>
                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Lead Name</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Pickup Date</TableCell>
                        <TableCell>Pickup Time</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leads.map((lead) => (
                        <TableRow key={lead.id}>
                          <TableCell>{lead.name}</TableCell>
                          <TableCell>{lead.address}</TableCell>
                          <TableCell>{lead.email}</TableCell>
                          <TableCell>{lead.pickupDate}</TableCell>
                          <TableCell>{lead.pickupTime}</TableCell>
                          <TableCell>{lead.status}</TableCell>
                          <TableCell>
                            <IconButton aria-label="edit" className={classes.iconButton} onClick={() => handleEdit(lead)}>
                              <Edit />
                            </IconButton>
                            <IconButton aria-label="delete" className={classes.iconButton} onClick={() => handleDeleteLead(lead.id)}>
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Edit Lead</DialogTitle>
        <DialogContent>
          {selectedLead && (
            <form>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Lead Name"
                type="text"
                fullWidth
                value={selectedLead.name}
                onChange={(e) => setSelectedLead({ ...selectedLead, name: e.target.value })}
              />
              <TextField
                margin="dense"
                id="address"
                label="Address"
                type="text"
                fullWidth
                value={selectedLead.address}
                onChange={(e) => setSelectedLead({ ...selectedLead, address: e.target.value })}
              />
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                value={selectedLead.email}
                onChange={(e) => setSelectedLead({ ...selectedLead, email: e.target.value })}
              />
              <TextField
                margin="dense"
                id="pickupDate"
                label="Pickup Date"
                type="date"
                fullWidth
                value={selectedLead.pickupDate}
                onChange={(e) => setSelectedLead({ ...selectedLead, pickupDate: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="dense"
                id="pickupTime"
                label="Pickup Time"
                type="time"
                fullWidth
                value={selectedLead.pickupTime}
                onChange={(e) => setSelectedLead({ ...selectedLead, pickupTime: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="dense"
                id="status"
                label="Status"
                type="text"
                fullWidth
                value={selectedLead.status}
                onChange={(e) => setSelectedLead({ ...selectedLead, status: e.target.value })}
              />
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveLead} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default StaffDashboard;
