import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Grid,  useMediaQuery,Container,Paper,Box} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Screen from './Screen';
import Defects from './Defects';
import Functional from './Functional';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import image1 from '../../assets/images/1.jpg';
import image2 from '../../assets/images/2.jpg';
import DeviceEvaluation from '../commonForms/DeviceEvaluation';

const ScreenDefects = ({ 
    selectedModel,
    deviceAnswers,
    rowDetails,
    formattedVariant,
    currentValue,
    warrantyStatus,
    FixedPrice,
    answers,
    userLogDetails,
    onFormData
}) => {
 
  const [showScreen, setShowScreen] = useState(false);
  const [showDefects, setShowDefects] = useState(false);
  const [showFunctional, setShowFunctional] = useState(false);
  const[ScreenDefects,setScreenDefects] = useState(true);
  const[showWarrantyPage,setshowWarrantyPage] = useState(true);
  const[scrdefCurrentValue,setscrdefCurrentValue] = useState(currentValue);
  const [selectedOptions, setSelectedOptions] = useState({
    screenCondition: [],
    defectsCondition: [],
  });
  const[DisableDefects,setDisableDefects] = useState(false);

  const warrantyConditions = [
    "touch",
    "screenOriginal",
    "Screen cracked/ glass broken",
    "Average:-major scraches, major dents,panel crackes, loose screen gap",
    "Below Average:-Heavy scraches,Heavy dents and phone bent",
    "Phone Bent",
    "Phone Bent With Panel Crack",
    "Only Panel Broken"
  ];
  
  // const shouldDeductWarranty = answers.warranty === "no" || answers.touch === "no" || answers.screenOriginal === "no" || warrantyConditions.some(condition => Object.values(selectedOptions).map(option => option.text).includes(condition));
 
  const isMobile = useMediaQuery('(max-width:600px)');

 

  const handleSelection = (categoryId, option) => {
    // console.log(categoryId, option, "selection details");
  
    setSelectedOptions((prevSelectedOptions) => {
      // Get the current selections for the given category
      const currentSelected = prevSelectedOptions[categoryId] || [];
  
      // Check if the option is already selected using its ID
      const isSelected = currentSelected.includes(option.id);
      // console.log(isSelected, "isSelected");
  
      if (isSelected) {
        // If already selected, remove it from the selected options
        const updatedSelection = currentSelected.filter(
          (selectedOptionId) => selectedOptionId !== option.id
        );
        return {
          ...prevSelectedOptions,
          [categoryId]: updatedSelection,
        };
      } else {
        // If not selected, add it to the selected options
        return {
          ...prevSelectedOptions,
          [categoryId]: [...currentSelected, option.id],
        };
      }
    });
  };
  
  
  
  

  const categories = [
    {
      id: 'screenCondition',
      title: 'Screen and Body Defects',
      options: [
        {
          id: 'screenDefect', // Unique ID for screen defect
          text: 'Screen Defects',
          image: image1,
        },
        {
          id: 'bodyDefect', // Unique ID for body defect
          text: 'Body Defects',
          image: image2,
        },
      ],
    },
  ];
  
  
const handleContinue = () => {

  // if (shouldDeductWarranty) {
  //   console.log(rowDetails["> 11 months"], "warranty deduction...");
  //   currentValue += rowDetails["> 11 months"];
  //   console.log(currentValue, "CRRR");
  //   setscrdefCurrentValue(currentValue);
  //   setshowWarrantyPage(false);
  // }

if(answers.warranty === "yes" && answers.touch === "yes" && answers.screenOriginal === "yes" ){
  setshowWarrantyPage(true);
}
    const selectedIds = selectedOptions.screenCondition || [];
   // Check if 'screenDefect' or 'bodyDefect' are selected
    const screenSelected = selectedIds.includes('screenDefect');
    const bodySelected = selectedIds.includes('bodyDefect');
  
    // console.log(screenSelected, "screenSelected");
    // console.log(bodySelected, "bodySelected");

      // Track deductions for touch and screen
      let touchDeduction = 0;
      let screenDeduction = 0;
  
    if (screenSelected && bodySelected) {
      setShowScreen(true); // Show screen logic if both are selected
      setScreenDefects(false);
      setDisableDefects(false);
    } else if (screenSelected) {
      setShowScreen(true); // Show screen logic if only screen is selected
      setScreenDefects(false);
      setDisableDefects(true);
    } else if (bodySelected) {
         // Check for touch not working
          if (answers.touch === 'no') {
            touchDeduction = rowDetails['Device Touch not working'];
            currentValue += touchDeduction;
            // console.log(currentValue,"touchDeduction")
            setscrdefCurrentValue(currentValue);
          }
        
          // Check for screen not original
          if (answers.screenOriginal === 'no') {
            screenDeduction = rowDetails["phone's screen original"];
            currentValue += screenDeduction;
            // console.log(currentValue,"screenDeduction")
            setscrdefCurrentValue(currentValue);
          }


           // Determine the deduction logic
      if (answers.touch === 'no' && answers.screenOriginal === 'no') {
        // If both conditions are 'no', take the maximum deduction value
        const maxDeduction = Math.min(touchDeduction, screenDeduction);
        // console.log(maxDeduction,"maxDeduction...")
        currentValue += maxDeduction;
        setscrdefCurrentValue(currentValue);
      } 
   


      setShowDefects(true); // Show defects logic if only body defects are selected
      setScreenDefects(false);
      setDisableDefects(false);
    } 

    else if(!screenSelected && !bodySelected){


        // Check for touch not working
        if (answers.touch === 'no') {
          touchDeduction = rowDetails['Device Touch not working'];
          currentValue += touchDeduction;
          // console.log(currentValue,"touchDeduction")
          setscrdefCurrentValue(currentValue);
        }
      
        // Check for screen not original
        if (answers.screenOriginal === 'no') {
          screenDeduction = rowDetails["phone's screen original"];
          currentValue += screenDeduction;
          // console.log(currentValue,"screenDeduction")
          setscrdefCurrentValue(currentValue);
        }


         // Determine the deduction logic
    if (answers.touch === 'no' && answers.screenOriginal === 'no') {
      // If both conditions are 'no', take the maximum deduction value
      const maxDeduction = Math.min(touchDeduction, screenDeduction);
      // console.log(maxDeduction,"maxDeduction...")
      currentValue += maxDeduction;
      setscrdefCurrentValue(currentValue);
    } 
      setShowFunctional(true);
      setScreenDefects(false);
      setShowScreen(false);
      setDisableDefects(true);

    }

  
  };
  
  
        
        

  const handleFormData = (data) => {
    // console.log("Defects:", data);
    onFormData(data)
    
  };

   
    // onDeviceDetails({
    //   deviceAnswers: {deviceAnswers},
    //   answers: answers,
    // })
  

 



  return (
    <div>
<Container>
      {ScreenDefects &&
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={7}>
            <Paper elevation={3} sx={{ padding: 3, borderRadius: 4 }}>
                  <Typography
                    variant="h6"
                    className="conditionsheading"
                    sx={{
                      marginBottom: 2,
                      fontWeight: 'bold',
                      color: '#333',
                      textAlign: 'center',
                    }}
                  >
                  Select Screen/Body Defects Conditions of Your Device
                  </Typography>
                  <Box
        sx={{
          // position: isMobile ? 'fixed' : 'static',
          bottom: isMobile ? 0 : 'auto',
          height: isMobile ? 'calc(100vh - 70px)' : 'auto', // Set height for mobile screens
          overflowY: isMobile ? 'auto' : 'initial', // Allow scrolling for mobile
          // paddingBottom: { xs: '80px', md: '0' },
          width: '100%',
          p: 2,
             // Hide scrollbar (cross-browser solution)
      '&::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar for WebKit browsers
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent', // Ensure it's completely transparent
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent', // Hide the scrollbar track
      },
      scrollbarWidth: 'none', // Hide scrollbar for Firefox
    }}
      >
              {categories.map((category) => (
                <Category
               
                  key={category.id}
                  categoryId={category.id}
                  title={category.title}
                  options={category.options}
                  selectedOption={selectedOptions[category.id]}
                  onSelection={handleSelection}
                />
              ))}
                 </Box>
         
  
                 <Box
              sx={{
                position: isMobile ? 'sticky' : 'static',
                bottom: isMobile ? 0 : 'auto',
                left: 0,
                width: '100%',
                backgroundColor: 'white',
                zIndex: 2,
                p: isMobile ? 2 : 0, // Padding for better spacing on mobile
              }}
            >
  <Button
  variant="contained"
  sx={{
  backgroundColor: '#4caf50',
  color: 'white',
  padding: isMobile ? '8px 16px' : '12px 24px',
  fontSize: isMobile ? '0.9rem' : '1.2rem',
  borderRadius: '8px',
  width: { xs: '100%', md: 'auto' }, // Full width on mobile, auto on larger screens
  marginBottom: { xs: '10px', md: '0' },
  '&:hover': {
  backgroundColor: '#45a049',
  },
  }}
  onClick={handleContinue}
  endIcon={<ArrowForwardIcon />}
  >
  Continue
  </Button>
  </Box>
                 
                </Paper>
            </Grid>
            {!isMobile && (
            <Grid item xs={12} md={5}>
              <DeviceEvaluation 
              selectedModel={selectedModel}
               deviceAnswers={{ ...deviceAnswers, ...selectedOptions }}
               formattedVariant={formattedVariant}
                answers={answers}


                />
            </Grid>
            )}
          </Grid>
    
         </Grid>
        }








{showScreen && (
 <Screen
 selectedModel={selectedModel}
 formattedVariant={formattedVariant}
 currentValue={scrdefCurrentValue}
 warrantyStatus={warrantyStatus}
 DisableDefects={DisableDefects}
 rowDetails={rowDetails}
 FixedPrice={FixedPrice}
 showWarrantyPage={showWarrantyPage}
 answers={answers}
 userLogDetails={userLogDetails}
 onFormData={handleFormData}
/>
    )}

{showDefects && (
        
        <Defects 
      selectedModel={selectedModel}
        formattedVariant={formattedVariant} 
        currentValue={scrdefCurrentValue} 
        warrantyStatus={warrantyStatus}
        rowDetails={rowDetails}
          FixedPrice={FixedPrice}
          answers={answers}
            ScreenselectedOptions={""}
            deviceAnswers={{ ...deviceAnswers, ...selectedOptions }}
            userLogDetails={userLogDetails}
            onFormData={handleFormData}
               />
       
    )}

{showFunctional && (
         
         <Functional 
         selectedModel={selectedModel} 
         formattedVariant={formattedVariant}
          currentValue={scrdefCurrentValue}
           warrantyStatus={warrantyStatus}
            rowDetails={rowDetails}
             answers={answers}
              FixedPrice={FixedPrice}
               showWarrantyPage={showWarrantyPage}
                deviceAnswers={{ ...deviceAnswers, ...selectedOptions }} 
                ScreenselectedOptions={""} 
                DefectsselectedOptions={selectedOptions} 
                userLogDetails={userLogDetails}
                onFormData={handleFormData}

                
                />
       
     )}
   
        
        </Container>
     
</div>

    
  );
};

// Updated Category component
const Category = ({ categoryId, title, options, selectedOption, onSelection }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <Typography variant="h6" className="conditionssubheading">{title}</Typography>
      <Grid container spacing={2}>
        {options.map((option) => (
          <Item
            key={option.id} // Use `id` for a unique key
            categoryId={categoryId}
            option={option}
            // Check if the option is present in the selectedOption array using `id`
            isSelected={selectedOption?.includes(option.id)}
            onClick={() => onSelection(categoryId, option)}
          />
        ))}
      </Grid>
    </div>
  );
};





// Updated Item component
const Item = ({ option, isSelected, onClick }) => {
  return (
    <Grid item xs={6} sm={6} md={6}>
      <Box
        onClick={onClick}
        sx={{
          cursor: 'pointer',  
          border: isSelected ? '2px solid green' : '1px solid #ddd',
          borderRadius: '12px',
          overflow: 'hidden',
          textAlign: 'center',
          boxShadow: isSelected ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          },
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <img
          src={option.image}
          alt={option.text}
          style={{
            width: '100%',
            height: '120px',
            objectFit: 'contain',
            borderBottom: '1px solid #ddd',
            padding: '10%',
          }}
        />
        <Typography
          variant="subtitle1"
          sx={{
            padding: '12px',
            fontSize: '10px',
            fontWeight: 'bold',
            color: isSelected ? 'green' : '#333',
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {option.text}
        </Typography>
      </Box>
    </Grid>
  );
};





export default ScreenDefects;
